/*
 * Session Messages
 *
 * This contains all the text for the utils / error messages.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.session';

export default defineMessages({
  signInTitle: {
    id: `${scope}.SignIn.title`,
    defaultMessage: 'Castalie',
  },
  signInButton: {
    id: `${scope}.SignIn.button`,
    defaultMessage: 'Se connecter',
  },
  forgotPassword: {
    id: `${scope}.SignIn.forgotPassword`,
    defaultMessage: 'Mot de passe oublié ?',
  },
  signInSignUp: {
    id: `${scope}.SignIn.signUp`,
    defaultMessage: 'Pas de compte? Enregistrez-vous',
  },
});
