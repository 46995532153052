const SESSION_COOKIE_NAME = 'C-SESSION';
const DAYS_LIMIT = 1;

const getSessionCookie = () => {
  const match = `; ${document.cookie}`.match(
    `;\\s*${SESSION_COOKIE_NAME}=([^;]+)`
  );
  return match && match[1];
};

const setSessionCookie = value => {
  if (getSessionCookie()) {
    document.cookie = `${SESSION_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  const date = new Date();
  date.setTime(date.getTime() + DAYS_LIMIT * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${SESSION_COOKIE_NAME}=${value || ''}${expires}; path=/`;
};

const flushCookie = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export { getSessionCookie, setSessionCookie, flushCookie };
