/*
 * Session Messages
 *
 * This contains all the text for the utils / error messages.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.location';

export default defineMessages({
  addressTitle: {
    id: `${scope}.Things.title`,
    defaultMessage: 'Localisée à:',
  },
});