import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Popover, Typography } from '@material-ui/core';

import { Error as ErrorIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import messages from './Messages';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1)
  },
  icon:{
    verticalAlign: 'middle'
  }
}));

const Alert = (alerts) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const alertList = Object.keys(alerts)
    .reduce((icons, name) => {
      if (alerts[name]) {
        icons.push(
          <Typography key={`alert-${name}`} component="p">
            <FormattedMessage {...messages[name]} />
          </Typography>
        );
      }
      return icons;
    }, []);
  if (!alertList.length) return '';

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ErrorIcon
        aria-describedby={id}
        variant="contained"
        onClick={handleOpen}
        fontSize="small"
        aria-haspopup="true"
        className={classes.icon}
      />
      <Popover
        id={id}
        open={open}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {alertList}
      </Popover>
    </>
  );
}

export default Alert;
