import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from "react-router-dom";
import { useSession } from '../../contexts/session';

import prettyMs from 'pretty-ms';

import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Grid,
  Paper,
  Button,
  Breadcrumbs,
  Link as MUILink,
  Typography,
} from '@material-ui/core';

import ConsumptionGraph from '../consumption-graph/ConsumptionGraph';
import Location from '../location/Location';
import StatCard from '../stat/StatCard';
import StatutBadge from '../interface/StatutBadge';
import Alert from './Alert';

import SetDose from './SetDose';
import Reboot from './Reboot';

import ThingGQL from './thing.gql';
import SendCommandGQL from './sendCommand.gql';

import messages from './Messages';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(4)
  },
  fixedHeight: {
    height: 240,
  },
  imgResponsive: {
    maxWidth: "100%"
  },
  imgCenter: {
    margin: '0 auto',
    display: 'block'
  }
}));

const Thing = ({ match: { params } }) => {
  const [open, setOpen] = useState('');
  const [openReboot, setOpenReboot] = useState('');

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen('');

  const openModalReboot = () => setOpenReboot(true);
  const closeModalReboot = () => setOpenReboot('');
  const { session } = useSession();

  const [sendCommand] = useMutation(SendCommandGQL);

  const { loading, error, data } = useQuery(ThingGQL, {
    variables: params,
    pollInterval: 25000,
  });
  const classes = useStyles();

  if (loading) return (<CircularProgress />);
  if (error) { console.error(error); return ''; }
  const { thing } = data;
  let admin = true;

  // TO CHANGE AFTER ALEX ANSWER

  if ( session.user.email.indexOf('castalie.com') === -1 && session.user.email !== "alexandre.leclercq@gmail.com" ){
    admin = false;
  }
  if (!thing) return <div>No machine</div>;

  const {
    id,
    serial,
    type,
    lastMessageAt,
    lastState,
    lastLocation,
    account,
    alert,
  } = thing;

  const reboot = () => sendCommand({ variables: { id, command: 'reboot' } });

  const {
    alerts,
    tankTemperature,
    lastConfigMessageAt,
    upTime,
  } = lastState || {};
 
  const {
    generalFault,
    debacterizationFault,
    deliveryFault,
    compressorFault,
    thermoblockFault,
    mixerMainFailure,
    waterLeak,
    drainerLevel,
    lidOpen,
    tiltAlert,
    configurationFault,
    microControllerFault,
    communicationFault,
    generalStateAlert,
    waterAlert,
    co2Alert,
    temperatureAlert,
  } = alerts || {};

  const lateMessage = new Date();
  lateMessage.setHours(lateMessage.getHours() - 1);
  const lastMessageAlert = !lastMessageAt || new Date(lastMessageAt) < lateMessage;

  const tankTemperatureAlert = tankTemperature && tankTemperature < 1;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <MUILink color="inherit" href="/things">
          <FormattedMessage {...messages.thingTitle} />
        </MUILink>
        <Typography color="textPrimary">{type}
        {admin &&
        <> ,
        <Link to={`/account/${account.id}`} >{account.name}</Link>
        </>}
        </Typography>
      </Breadcrumbs>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Grid container>
              <SetDose
                thing={thing}
                open={open || false}
                closeModal={closeModal}
                lastConfigMessageAt={lastConfigMessageAt} />

              <Reboot
                open={openReboot || false}
                closeModal={closeModalReboot}
                reboot={reboot} />

              <Grid item xs={12}>

                {type.indexOf("Colonne")!== -1? 
                  <img alt="" className={classes.imgCenter} width="200px" src="/colonne.png" />
                :  <img alt="" className={classes.imgCenter} width="200px" src="/pure.jpg" />}
                
              </Grid>

              <Grid item xs={12}>
                <h1>
                  <StatutBadge color={alert ? 'danger' : 'success'} />
                  <Alert
                    generalFault={generalFault}
                    mixerMainFailure={mixerMainFailure}
                    tiltAlert={tiltAlert}
                    configurationFault={configurationFault}
                    microControllerFault={microControllerFault}
                    communicationFault={communicationFault}
                    lidOpen={lidOpen} />

                  {type}
                </h1>
                <ul>
                  
                  <li><FormattedMessage {...messages.serial} /> {serial || '-'}</li>

                  {/* {lastState && <li>
                    <FormattedMessage {...messages.upTime} />
                    {upTime ? prettyMs(upTime, { compact: true }) : '-'}
                  </li>
                  } */}
                  {/* <Location location={lastLocation} /> */}
                  {/* <Location location={lastLocation} />
                  <li>IMEI: {imei}</li>
                  <li>ICCID: {iccid}</li> */}
                </ul>
                  
                <Typography component="p">
                  <StatutBadge color={generalStateAlert ? 'danger' : 'success'} />
                  <FormattedMessage {...messages.connexion} />
                </Typography>
                

                <Typography component="p">
                  <StatutBadge color={lastMessageAlert ? 'danger' : 'success'} />
                  <FormattedMessage {...messages.lastMessage} />
                  {prettyMs(new Date() - new Date(lastMessageAt), { compact: true })}
                </Typography>
                
                {lastState &&
                  <Typography component="p">
                    <StatutBadge color={temperatureAlert ? 'danger' : 'success'} />
                    <Alert
                      thermoblockFault={thermoblockFault}
                      tankTemperatureAlert={tankTemperatureAlert} />
                    <FormattedMessage {...messages.tankTemperature} />
                    {(upTime || tankTemperature) ?
                      `${tankTemperature} °C` : '-'}
                  </Typography>
                }

                
                
                <Typography component="p">
                  <StatutBadge color={waterAlert ? 'danger' : 'success'} />
                  <Alert
                    debacterizationFault={debacterizationFault}
                    deliveryFault={deliveryFault}
                    compressorFault={compressorFault}
                    waterLeak={waterLeak}
                    drainerLevel={drainerLevel} />
                  <FormattedMessage {...messages.water} />
                </Typography>

                <Typography component="p">
                  <StatutBadge color={co2Alert ? 'danger' : 'success'} />
                  CO2
                </Typography>

                {admin &&
                <>
                <br />
                <Button size="small" variant="contained" onClick={openModalReboot} color="primary" className={classes.button}>
                  <FormattedMessage {...messages.reboot} />
                </Button>
                <Button size="small" variant="contained" onClick={openModal} className={classes.button}>
                  <FormattedMessage {...messages.setDoseButton} />
                </Button>
                </>}
              </Grid>
            </Grid>

          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={3}>
            <StatCard stat={lastState} type="bottle"/> 
            <StatCard stat={lastState} type="co2"/> 
            <Location location={lastLocation} /> 
          </Grid>
        </Grid>
        {admin &&
        <>
        <ConsumptionGraph thingId={id} />
        </>}
      </Grid>
    </>
  );
}

export default Thing;
