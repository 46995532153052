/*
 * Session Messages
 *
 * This contains all the text for the utils / error messages.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.session';

export default defineMessages({
  forgotPassword: {
    id: `${scope}.SignIn.forgotPassword`,
    defaultMessage: 'Mot de passe oublié ?',
  },
  signUpTitle: {
    id: `${scope}.SignUp.title`,
    defaultMessage: 'M\'enregistrer',
  },
  signUpButton: {
    id: `${scope}.SignUp.button`,
    defaultMessage: 'M\'enregistrer',
  },
  signUpSignIn: {
    id: `${scope}.SignUp.signIn`,
    defaultMessage: 'Déjà un compte ? connectez-vous',
  },
  renewPassword: {
    id: `${scope}.ForgotPassword.button`,
    defaultMessage: 'Renouveler',
  },
  forgotPasswordTitle: {
    id: `${scope}.ForgotPassword.title`,
    defaultMessage: 'Entrer votre adresse email et nous vous enverrons un lien pour renouveller votre mot de passe',
  },
  updatePasswordTitle: {
    id: `${scope}.UpdatePassword.title`,
    defaultMessage: 'Renouveler mon mot de passe',

  }

});
