import React from 'react';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';


import useSignIn from './useSignIn';
import SignIn from './SignIn';

const SignInContainer = () => {
  const { session, validationSchema, redirection, handleSubmit, ...initialValues } = useSignIn();

  if (session) return <Redirect to={redirection || '/'} />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={() => <SignIn />} />
  );
};

export default SignInContainer;
