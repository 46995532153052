import React, { useState } from 'react';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { Mutation, Query } from '@apollo/react-components';
import LanguageProvider from '../../config/LanguageProvider';
import Routes from '../../routes/Routes';
import SessionContext from '../../contexts/session';

import { translationMessages, DEFAULT_LOCALE } from '../../i18n';

import sessionGQL from './session.gql';
import signInGQL from './signIn.gql';
import signOutGQL from './signOut.gql';
import signUpGQL from '../users/createUser.gql';

const useSession = () => {
  const [session, setInternalSession] = useState(null);
  const [redirection, setInternalRedirection] = useState(null);
  const [locale] = useState(DEFAULT_LOCALE);
  const setSession = (newSession, newRedirection) => {
    if (newRedirection) {
      setInternalRedirection(newRedirection);
    }
    setInternalSession(newSession && newSession.token ? newSession : null);
    return '';
  };

  const setRedirection = () => {
    if (!redirection) return '';
    const newRedirection = redirection.slice();
    setInternalRedirection(null);
    return <Redirect to={newRedirection} />
  }

  return { session, setSession, locale, redirection, setRedirection };
}

const SessionContainer = () => {
  const { session, setSession, locale, redirection, setRedirection } = useSession();
  
  return (
    <Query query={sessionGQL}>
      {({ loading, error, data: newSession }) => {
        if (loading) return '';
        if (error) console.error(error);
        if (!session && newSession && newSession.session && newSession.session.token && !redirection) {
          return setSession(newSession.session);
        }
        return (
          <Mutation
            mutation={signInGQL}
            onCompleted={({ signIn: session }) => setSession(session, newSession && newSession.token && '/')} >
            {signIn => (
              <Mutation
                mutation={signUpGQL}
                onCompleted={() => setSession(null, '/sign-in')} >
                {signUp => (
                  <Mutation
                    mutation={signOutGQL}
                    onCompleted={() => setSession(null, '/sign-in')} >
                    {signOut => (
                      <SessionContext.Provider value={{ session, signIn, signUp, signOut, setSession, redirection }}>
                        <LanguageProvider
                          locale={locale}
                          defaultLocale={(session && session.locale) || DEFAULT_LOCALE}
                          messages={translationMessages}
                        >
                          <BrowserRouter>
                            {
                              redirection
                                ? setRedirection() :
                                <Routes />
                            }
                          </BrowserRouter>
                        </LanguageProvider>
                      </SessionContext.Provider>
                    )}
                  </Mutation>
                )}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default SessionContainer;
