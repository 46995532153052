import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Button,
  Link,
  Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import messages from './Messages';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = () => {
  const classes = useStyles();

  return (
    <>
      <img src="/logo-center.png" alt='Logo Castalie'></img>
      <Form className={classes.form}>
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          <FormattedMessage {...messages.signInButton} />
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              <FormattedMessage {...messages.forgotPassword} />
            </Link>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

export default SignIn;
