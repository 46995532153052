import React from 'react';
import { FormattedMessage } from 'react-intl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSession } from '../../contexts/session';

import {
  AppBar,
  Toolbar,
  Typography,
  Link,
} from '@material-ui/core';

import messages from './Messages';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.14)',
    backgroundColor: '#FFFFFF'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  }
  
}));

const Header = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { session } = useSession();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="absolute" className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbar}>
        <Typography noWrap className={classes.title}>
          <Link href="/">  
            <img src="/logo.png" alt="Castalie" width="150"/>
          </Link>
        </Typography>
        <Link aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {session.user.firstname} {session.user.lastname}
        </Link>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem href="/care" component={Link} to="/contact"><FormattedMessage {...messages.support} /></MenuItem>
          <MenuItem href="/sign-out" component={Link} to="/sign-out"><FormattedMessage {...messages.logout} /></MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
