import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
// import { WebSocketLink } from 'apollo-link-ws';
// import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';
import QueryString from 'query-string';

import { getSessionCookie } from './SessionCookie';

const isDevelopmentEnv = process.env.NODE_ENV !== 'sandbox' && process.env.NODE_ENV !== 'production';
// const wsProtocol = !isDevelopmentEnv ? 'wss' : 'ws';
const publicUrl = process.env.PUBLIC_URL || 'http://localhost:3000';
const protocol = publicUrl.match(/^([a-z]+):/)[1];
const host = publicUrl.match(/^[a-z]+:\/\/([a-z0-9.-]+):?[0-9]{0,5}/)[1] || 'localhost';
const tempPort = isDevelopmentEnv ? publicUrl.match(/^[a-z]+:\/\/[a-z0-9.-]+:?([0-9]{0,5})/)[1] || 3000 : '';
const graphqlPort = tempPort ? `:${tempPort}` : '';
const graphqlPath = process.env.GRAPHQL_PATH || '/graphql';

// Create an http link:
const httpLink = createUploadLink({ uri: `${protocol}://${host}${graphqlPort}${graphqlPath}` });

// Create a WebSocket link:
// const wsLink = new WebSocketLink({
//   uri: `${wsProtocol}://${host}:${graphqlPort}${graphqlPath}`,
//   options: {
//     reconnect: true,
//     connectionParams: { authHeader: getSessionCookie() }
//   }
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const qs = QueryString.parse(window.location.href);
  const token = getSessionCookie() || qs.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query);
//     return kind === 'OperationDefinition' && operation === 'subscription';
//   },
//   wsLink,
//   httpLink
// );

const apolloClient = new ApolloClient({
  // link: authLink.concat(link),
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;
