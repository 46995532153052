/*
 * Account Messages
 *
 * This contains all the text for the utils / error messages.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.account';

export default defineMessages({
  accountTitle: {
    id: `${scope}.Account.title`,
    defaultMessage: 'Account',
  }
});
