import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import messages from './Messages';

const useStyles = makeStyles(theme => ({

  dialog: {
    padding: theme.spacing(2),
  },
  hidden: {
    display: 'none'
  },
  form:{
    display: 'flex',
    flexDirection: 'row'
  },
  imgResponsive: {
    width: "300px",
    margin: "0 auto",
    display: "block"
  }
}));

const Reboot = ({
  open,
  reboot,
  closeModal,
}) => {
  const classes = useStyles();

  if (!open) return '';

  const okToReboot = () => {
    reboot()
    closeModal()
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="alert-dialog-title"><FormattedMessage {...messages.reboot} /></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage {...messages.rebootInvite} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          <FormattedMessage {...messages.close} />
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={okToReboot}
        >
          OK
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default Reboot;
