import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Avatar,
  Button,
  Link,
  Grid,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import messages from './Messages';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdatePassword = ({ oldPasswordRequired }) => {
  const classes = useStyles();

  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        <FormattedMessage {...messages.updatePasswordTitle} />
      </Typography>
      <Form className={classes.form}>
        {oldPasswordRequired && (
          <Field
            component={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="oldpassword"
            label="Old Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
        )}

        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          type="password"
          id="password"
          autoComplete="new-password"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          <FormattedMessage {...messages.renewPassword} />
        </Button>
        <Grid container>
          <Grid item>
            <Link href="/sign-in" variant="body2">
              <FormattedMessage {...messages.signUpSignIn} />
            </Link>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

export default UpdatePassword;
