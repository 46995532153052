/*
 * Session Messages
 *
 * This contains all the text for the utils / error messages.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.statcard';

export default defineMessages({
  bottleSaved: {
    id: `${scope}.stat.bottle_saved`,
    defaultMessage: "Bouteilles de plastiques économisées pour vous & la planète",
  },
  co2Saved: {
    id: `${scope}.stat.co2_saved`,
    defaultMessage: "kg de CO2 éconnomisé grâce à vos fontaines Castalie",
  },

  
});
