import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import verifyUserGQL from './verifyUser.gql';

const useVerifyUser = ({ token }) => {
  const [redirection, setRedirection] = useState(null);
  const [verifyUser] = useMutation(verifyUserGQL);

  useEffect(() => {
    verifyUser({ variables: { token } });
    setRedirection(true);
  }, [redirection, token, verifyUser]);

  return redirection;
};

export default useVerifyUser;
