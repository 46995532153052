const castalieTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    text: {
      primary: "#333",
    },
    primary: {
      light: '#1074F6',
      main: '#1074F6',
      dark: '#1074F6',
      contrastText: 'white',
    },
    textFieldsOutline: '#87caef',
    TextFieldsOutlineHovered: '#000000',
    warningOrange: '#FFAF39',
    darkBlue: '#87caef',
    warning: 'red',
    warningShadow: '0px 1px 5px rgba(206,0,0,0.28)',
    selectionBlue: '#84c9f1',
    lightBlue: '#8aeef2',
    disabled: '#d6d6d6',
    hoverBut2: '#dadada',
    inactiveLight: '#ececec',
    inactive: '#bababa',
    inactiveSelect: '#ababab',
    grayed: '#6e6e6e',
    uploadText: '#bdbdbd',
    hovered: '#d6d6d62b',
    // to modify gradient if needed: https://cssgradient.io/
    secondaryBackground: '#FFFFFF',
    standardTextColorWithBackground: '#FFFFFF',
    standardTextColorNoBackground: '#333333',
    textFieldOverride: '#ababab'
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Avenir Next', 'Arial', 'sans-serif'].join(
      ','
    ),
    fontSize: 14,
    h1: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 300,
      fontSize: '2.3rem',
      lineHeight: 1,
      letterSpacing: '-0.01562em'
    },
    h2: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 'bold',
      fontSize: '1.5rem'
    },
    subtitle1: {
      color: '#bababa',
      fontSize: '1em',
      opacity: '0.9',
      lineHeight: 1.25
    },
    button: {
      color: 'white',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em'
    }
  },
  custom: {
    borderRadius: 8,
    standardOpacity: 0.75,
    standardShadow: '0px 79px 112px -86px rgba(0,0,0,0.81)'
    // to modify box shadow if needed: https://www.cssmatic.com/box-shadow
  },
  overrides: {
    // Name of the ⚛️ component / style sheet
    MuiMenuItem:{
      root: {
        color: '#1074F6'
      }
    },
    MuiLinearProgress: {
      // Name of the rule
      colorPrimary: {
        backgroundColor: '#bababa'
      },
      colorSecondary: {
        backgroundColor: '#bababa'
      },
      barColorPrimary: {
        backgroundColor: '#6ddeff'
      },
      barColorSecondary: {
        backgroundColor: '#bababa'
      }
    },
    MuiRadio: {
      /* Styles applied to the root element if `color="primary"`. */
      colorPrimary: {
        '&$checked': {
          color: '#000000'
        },
        '&$disabled': {
          color: '#d6d6d6'
        }
      }
    }
  }
};

export default castalieTheme;