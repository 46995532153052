import React from 'react';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';

import useSignUp from './useSignUp';
import SignUp from './SignUp';

const SignUpContainer = ({ match: { params } }) => {
  const {
    session,
    validationSchema,
    handleSubmit,
    ...initialValues
  } = useSignUp(params);

  if (session) return <Redirect to="/" />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={() => <SignUp />} />
  );
};

export default SignUpContainer;
