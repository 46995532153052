import React from 'react';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import useVerifyUser from './useVerifyUser';

const VerifyUser = ({ match: { params } }) => {
  const redirection = useVerifyUser(params);

  if (redirection) return (<Redirect to="/" />);
  return (<CircularProgress />);
};

export default VerifyUser;
