import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSession } from '../contexts/session';

import { CssBaseline, Container } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/layout/Header';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#F0F7FF'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const renderComponent = (props, Component, classes) => (
  <div className={classes.root}>
    <CssBaseline />
    <Header />
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Component {...props} />
      </Container>
    </main>
  </div>
);


const PrivateRoute = ({ component: Component, ...routeParams }) => {
  const { session } = useSession();
  const classes = useStyles();

  return (<Route
    {...routeParams}
    component={props => session ? renderComponent(props, Component, classes) : <Redirect to="/sign-in" />
    } />);
};

export default PrivateRoute;
