import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  Snackbar
} from '@material-ui/core';

import Graph from './Graph';

import MeasurementsGQL from './measurements.gql';

const ConsumptionGraph = ({ thingId, accountId }) => {
  const { loading, error, data } = useQuery(
    MeasurementsGQL, { variables: accountId ? { accountId } : { thingId } }
  );

  if (loading) return null;

  if (error) return (<Snackbar open autoHideDuration={6000} message={'Error Measurements'} />);

  const { measurements } = data;
  if (measurements.length < 2) return null;

  const categories = [];
  const waterConsumption = [];

  let ePower = [
    { name: 'Consommation électrique instantanée générale (Watts)', data: [], zero: true },
    { name: 'Consommation électrique instantanée agitateur (Watts)', data: [], zero: true },
    { name: 'Consommation électrique instantanée pompe (Watts)', data: [], zero: true },
    { name: 'Consommation électrique instantanée thermobloc (Watts)', data: [], zero: true },
    { name: 'Consommation électrique instantanée groupe froid (Watts)', data: [], zero: true },
    { name: 'Consommation électrique instantanée sortie 11 (Watts)', data: [], zero: true },
  ];

  let deliveries = [
    { name: 'Eau plate 1 (L)', data: [], zero: true },
    { name: 'Eau tempérée 2 (L)', data: [], zero: true },
    { name: 'Eau tempérée 3 (L)', data: [], zero: true },
    { name: 'Eau pétillante 4 (L)', data: [], zero: true },
  ];

  let measurementIndex = 0;

  let maxEPower = 0;
  let maxDeliveries = 0;

  for (measurementIndex = 0; measurementIndex <= (measurements.length -1); measurementIndex += 1) {
    const measurement = measurements[measurementIndex];
    const date = new Date(measurement.start);
    categories.push(date.toLocaleDateString());

    maxEPower = Math.max(
      maxEPower,
      measurement.ePower,
      measurement.ePowerMixer,
      measurement.ePowerPump,
      measurement.ePowerThermoblock,
      measurement.ePowerColdGroup,
      measurement.ePowerOutputEleven,
    );

    maxDeliveries = Math.max(
      maxDeliveries,
      measurement.delivery1Volume/100,
      measurement.delivery2Volume/100,
      measurement.delivery3Volume/100,
      measurement.delivery4Volume/100,
    );

    ePower[0].data.push(measurement.ePower);
    if (measurement.ePower && ePower[0].zero)
      delete ePower[0].zero;
    ePower[1].data.push(measurement.ePowerMixer);
    if (measurement.ePowerMixer && ePower[1].zero)
      delete ePower[1].zero;
    ePower[2].data.push(measurement.ePowerPump);
    if (measurement.ePowerPump && ePower[2].zero)
      delete ePower[2].zero;
    ePower[3].data.push(measurement.ePowerThermoblock);
    if (measurement.ePowerThermoblock && ePower[3].zero)
      delete ePower[3].zero;
    ePower[4].data.push(measurement.ePowerColdGroup);
    if (measurement.ePowerColdGroup && ePower[4].zero)
      delete ePower[4].zero;
    ePower[5].data.push(measurement.ePowerOutputEleven);
    if (measurement.ePowerOutputEleven && ePower[5].zero)
      delete ePower[5].zero;

    deliveries[0].data.push(measurement.delivery1Volume/100);
    if (measurement.delivery1Volume && deliveries[0].zero)
      delete deliveries[0].zero;
    deliveries[1].data.push(measurement.delivery2Volume/100);
    if (measurement.delivery2Volume && deliveries[1].zero)
      delete deliveries[1].zero;
    deliveries[2].data.push(measurement.delivery3Volume/100);
    if (measurement.delivery3Volume && deliveries[2].zero)
      delete deliveries[2].zero;
    deliveries[3].data.push(measurement.delivery4Volume/100);
    if (measurement.delivery4Volume && deliveries[3].zero)
      delete deliveries[3].zero;
    waterConsumption.push(Math.floor(measurement.waterConsumption/100) );
  }

  const minWater = measurements[0].waterConsumption/100;
  const maxWater = (measurements[measurements.length - 1].waterConsumption)/100;

  ePower = ePower.reduce((series, power) => {
    if (!power.zero)
      series.push(power);
    return series;
  }, []);

  deliveries = deliveries.reduce((series, delivery) => {
    if (!delivery.zero)
      series.push(delivery);
    return series;
  }, []);

  // CHECK
  // var totals = 0;
  // for (var i = deliveries.length - 1; i >= 0; i--) {
  //   for (var y in deliveries[i].data) {

  //     console.log(deliveries[i].data[y])
  //     totals+=deliveries[i].data[y];
  //   }
  // }
  // console.log(deliveries)
  // console.log(totals)

  return ([
    (maxDeliveries && <Graph
      title="Consommation instantanée en eau"
      categories={categories}
      series={deliveries}
      unit="Liters"
      max={maxDeliveries + 10}
      key="graph-deliveries" />) || '',
    (maxWater && <Graph
      title="Consommation cumulée en eau"
      categories={categories}
      series={[{
        name: 'Consommation eau',
        data: waterConsumption,
      }]}
      unit="Liters"
      min={Math.max(0, minWater - 100)}
      max={maxWater + 100}
      key="graph-water-consumption" />) || '',
    (maxEPower && <Graph
      title="Consommation électrique"
      categories={categories}
      series={ePower}
      unit="Watts"
      max={maxEPower + 5}
      key="graph-e-power" />) || '',
  ]);
}

export default ConsumptionGraph;
