import React from 'react';
import { Route } from 'react-router-dom';

import { CssBaseline, Container } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const renderComponent = (props, Component, classes) => (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Component {...props} />
    </div>
  </Container>
);


const PublicRoute = ({ component: Component, ...routeParams }) => {
  const classes = useStyles();

  return (<Route
    {...routeParams}
    component={props => renderComponent(props, Component, classes)} />);
};

export default PublicRoute;
