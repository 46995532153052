import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Button,
  Link,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import messages from './Messages';

const useStyles = makeStyles(theme => ({
  avatar: {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUp = () => {
  const classes = useStyles();

  return (
    <>
      <img src="/logo-center.png" alt='Logo Castalie'></img>
     
      <Field
        component={TextField}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="firstname"
        label="First Name"
        name="firstname"
        autoComplete="given-name"
        autoFocus
      />
      <Field
        component={TextField}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="lastname"
        label="Last name"
        name="lastname"
        autoComplete="family-name"
        autoFocus
      />
      <Form className={classes.form}>
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="new-password"
        />
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="passwordConfirmation"
          label="Password confirmation"
          type="password"
          id="passwordConfirmation"
          autoComplete="new-password"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          <FormattedMessage {...messages.signUpButton} />
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              <FormattedMessage {...messages.forgotPassword} />
            </Link>
          </Grid>
          <Grid item>
            <Link href="/sign-in" variant="body2">
              <FormattedMessage {...messages.signUpSignIn} />
            </Link>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

export default SignUp;
