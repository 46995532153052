import React from 'react';
import * as yup from 'yup';
import { Mutation } from '@apollo/react-components';
import { Formik } from 'formik';

import ForgotPassword from './ForgotPassword';
import forgotPasswordGQL from './forgotPassword.gql';

import Schema from '../../config/Schema';

const ForgotPasswordContainer = () => {
  const validationSchema = yup.object().shape({
    email: Schema.email,
  });

  return (
    <Mutation mutation={forgotPasswordGQL}>
      {forgotPassword => (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={({ email }) => forgotPassword({ variables: { email } })}
          render={() => <ForgotPassword />} />
      )}
    </Mutation>
  );
};

export default ForgotPasswordContainer;
