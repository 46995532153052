import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
} from '@material-ui/core';

import 'tui-chart/dist/tui-chart.css';
import { LineChart } from '@toast-ui/react-chart';


import castalieTheme from '../../styles/castalie';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(5),
    color: castalieTheme.palette.primary.light
  },
  title: {
    fontSize: '1.5em',
    fontWeight: 'bold'
  },
}));

const Graph = ({ title, categories, series, unit, min = 0, max }) => {
  const classes = useStyles();

  const data = {
    categories,
    series,
  }

  const options = {
    chart: {
      width: 1000,
      height: 500,
      title
    },
    yAxis: {
      title,
      pointOnColumn: true,
      min,
      max,
      suffix: unit[0],
    },
    xAxis: {
      title: 'Date',
      pointOnColumn: true,
      dateFormat: 'YYYY-MM-dd',
      tickInterval: 'auto'
    },
    series: {
      showDot: false,
      zoomable: true
    },
    tooltip: {
      suffix: unit[0]
    }
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper className={classes.paper}>
        <LineChart data={data} options={options} />
      </Paper>
    </Grid>
  );
}

export default Graph;
