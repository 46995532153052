import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  success: {
    background: '#26DE81',
    border: 0,
    borderRadius: 50,
    height: 14,
    width: 14,
    display: "inline-block",
    marginRight: "5px",
    verticalAlign: "middle",

  },
  warning: {
    background: '#F5A623',
    border: 0,
    borderRadius: 50,
    height: 14,
    width: 14,
    display: "inline-block",
    marginRight: "5px",
    verticalAlign: "middle",

  },
  danger: {
    background: '#FF0404',
    border: 0,
    borderRadius: 50,
    height: 14,
    width: 14,
    display: "inline-block",
    marginRight: "5px",
    verticalAlign: "middle",

  }
}));

const StatutBadge = ({color}) => {
  const classes = useStyles()
  return(
    <span className={classes[color]}></span>
  )
}


export default StatutBadge;
