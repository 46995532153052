/*
 * Session Messages
 *
 * This contains all the text for the utils / error messages.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.dashboard';

export default defineMessages({
  dashboardTitle: {
    id: `${scope}.Dashboard.title`,
    defaultMessage: 'Dashboard',
  },
  logout: {
    id: `${scope}.Dashboard.logout`,
    defaultMessage: 'Déconnexion',
  },
  myProfile: {
    id: `${scope}.Dashboard.myProfile`,
    defaultMessage: 'Mon profil',
  },
  support: {
    id: `${scope}.Dashboard.support`,
    defaultMessage: 'Assistance',
  },
  
  
});
