import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4)
  },
  imgResponsive: {
    maxWidth: "100%"
  },
  imgCenter: {
    margin: '0 auto',
    display: 'block'
  }
}));

const Contact = ({color}) => {
  const classes = useStyles()
  return(
    <>
      <h1>Assistance Castalie</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <img src="/icon-entretien.png" className={classes.imgCenter} alt=""/>
            <Typography align="center" variant="h2">
            SAV
            </Typography>
            <p>Toutes vos questions concernant vos entretiens trimestriels ou l'état de vos fontaines </p>
            <ul>
              <li>Email: <a href="mailto:support@castalie.com">support@castalie.com</a></li>
              <li>Téléphone: 01 46 20 20 19</li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <img src="/icon-sales.png" className={classes.imgCenter} alt=""/>
            <Typography align="center" variant="h2">
            Achat
            </Typography>
            <p>Pour l'achat de bonbonnes de CO2, bouteilles, gourdes ou nouvelles fontaines </p>
            <ul>
              <li>Email: <a href="mailto:sales@castalie.com">sales@castalie</a></li>
              <li>Téléphone: 01 46 20 20 17</li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <img src="/icon-feedback.png" className={classes.imgCenter} alt=""/>
            <Typography align="center" variant="h2">
            Retours interface
            </Typography>
            <p>Tous vos retours concernant l'interface pour améliorer votre expérience utilisateur </p>
            <ul>
              <li>Email: <a href="mailto:interface@castalie.com">interface@castalie.com</a></li>
              <li>Téléphone: 01 46 20 20 19</li>
            </ul>
          </Paper>
        </Grid>

      </Grid>
    </>
      
  )
}


export default Contact;
