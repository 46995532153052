import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import apolloClient from './config/ApolloClient';
import castalieTheme from './styles/castalie';
import SessionContainer from './components/session/SessionContainer';

const muiTheme = createMuiTheme(castalieTheme);

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <SessionContainer />
      </MuiThemeProvider>
    </ApolloProvider>);
}

export default App;