import * as yup from 'yup';
import { useSession } from '../../contexts/session';

import Schema from '../../config/Schema';

const validationSchema = yup.object().shape({
  email: Schema.email,
  password: Schema.password,
});

const useSignIn = () => {
  const { session, signIn, redirection } = useSession();

  const handleSubmit = ({ email, password }) => {
    signIn({ variables: { email, password } });
  };

  return {
    email: '',
    password: '',
    session,
    redirection,
    validationSchema,
    handleSubmit,
  }
};

export default useSignIn;