import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import ThingsGQL from '../things/things.gql';

import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Grid,
  Paper,
  Breadcrumbs,
  Typography,
} from '@material-ui/core';

import ConsumptionGraph from '../consumption-graph/ConsumptionGraph';
import ThingsTable from '../things/ThingsTable';

import AccountGQL from './account.gql';

import messages from './Messages';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(4)
  },
  fixedHeight: {
    height: 240,
  },
  imgResponsive: {
    maxWidth: "100%"
  },
  imgCenter: {
    margin: '0 auto',
    display: 'block'
  },
  table: {
    cursor: 'pointer'
  },
}));

const Account = ({ match: { params } }) => {
  const { loading, error, data } = useQuery(AccountGQL, { variables: params });
  const { loading: thingsLoading, error: thingsError, data: thingsData } =
    useQuery(ThingsGQL, { variables: { accountId: params.id } });

  const classes = useStyles();

  if (loading || thingsLoading) return (<CircularProgress />);
  if (error || thingsError) { console.error(error|| thingsError); return ''; }

  const { account } = data;
  if (!account) return <div>No Account</div>;

  const { things } = thingsData;

  const {
    companyId,
    name,
    address,
    zipCode,
    city,
    country,
    phone,
  } = account;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <FormattedMessage {...messages.accountTitle} />
        <Typography color="textPrimary">{account.name}</Typography>
      </Breadcrumbs>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <h1>
                  {name}, {companyId}
                </h1>
                <p>{address}</p>
                <p>{zipCode} {city}, {country}</p>
                <p>{phone}</p>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <ConsumptionGraph accountId={account.id} />
        { (things || '') && <ThingsTable things={things} />}
      </Grid>
    </>
  );
}

export default Account;
