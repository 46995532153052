import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import messages from './Messages';
import castalieTheme from '../../styles/castalie';
import {
  Grid,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(5),
      color: castalieTheme.palette.primary.light
    },
    statTitle: {
      fontSize: '3em',
      fontWeight: 'bold'
    },
    statDescription: {
      fontSize: '1.3em', 
      marginTop: '0'
    },
    statIcon: {
      backgroundImage : 'url("/sprites.png")',
      width: '100px',
      height: '140px',
      float: 'left',
      backgroundSize: '410%',
      backgroundPosition: '10px 0'
    },
    statIcon2: {
      backgroundImage : 'url("/sprites.png")',
      width: '100px',
      height: '140px',
      float: 'left',
      backgroundSize: '410%',
      backgroundPosition: '-100px 0'
    }
}));

const StatCard = ({ stat, type }) => {
  const classes = useStyles();
  if (!stat) return null;
  const co2 = Math.round( (stat.delivery1Volume + stat.delivery3Volume+ stat.delivery4Volume) *(0.18596)/100 );
  const bottle = Math.round( (stat.delivery1Volume + stat.delivery3Volume + stat.delivery4Volume) *2/100 );
  
  let widget_data = co2
  let message = messages.co2Saved
  let iconClass = classes.statIcon2

  if(type === 'bottle'){
    widget_data = bottle
    message = messages.bottleSaved
    iconClass = classes.statIcon
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
        <Paper className={classes.paper}>
        <div className={iconClass}></div>

        <span className={classes.statTitle}>
            {widget_data}
        </span>
        <p className={classes.statDescription}><FormattedMessage {...message}/></p>
        </Paper>
    </Grid>
  );
}

export default StatCard;
