import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line no-unused-vars
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';

import {
  Grid,
  Paper
} from '@material-ui/core';

import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

import messages from './Messages';

const useStyles = makeStyles(theme => ({
  mapLayout: {
    width: '100%',
    height: '239px',
  },

}));

const Location = ({ location }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [address, setAddress] = useState('');
  if (!location) return null;
  const {
    latitude: lat,
    longitude: lng,
    street,
    streetNumber,
    city,
  } = location;

  const position = { lat, lng };
  const hasAddress = (location.street || location.city || '');
  const { L } = window;
  const geocoder = L.Control.Geocoder.nominatim();
  const customMarker = L.icon({ iconUrl: '/favicon-32x32.png', })

   geocoder.reverse(
    position,
    1,
    results => {
      var r = results[0];
      if (r) {
        setAddress(`${r.properties.address.postcode} ${r.properties.address.town}`);
      }
    }
  );
  //const gmapUrl = `http://maps.google.com/?q=${location.latitude},${location.longitude}&z=3`;
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper>
        
        
      <Map center={position} zoom={13} className={classes.mapLayout}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        <Marker icon={customMarker} position={position}>
          {hasAddress && (
            <Popup>
              <h3><FormattedMessage {...messages.addressTitle} /></h3>
              {street && <p>{streetNumber} {street}</p>}
              <p>{city}</p>
            </Popup>
          )}
        </Marker>
      </Map>
    </Paper>
    </Grid>
  );
}

export default Location;
