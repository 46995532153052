import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { useMutation } from '@apollo/react-hooks';

import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import SetDoseGQL from './setDose.gql';

import messages from './Messages';

const DELIVERIES = ['1A', '1B', '2A', '2B', '3A', '3B', '4A', '4B'];

const validationSchema = yup.object().shape(
  DELIVERIES.reduce((schema, item) => {
    schema[`delivery${item}`] = yup.number();
    return schema;
  }, {})
);

const useStyles = makeStyles(theme => ({

  dialog: {
    padding: theme.spacing(2),
  },
  hidden: {
    display: 'none'
  },
  form: {
    display: 'flex',
    flexDirection: 'row'
  },
  imgResponsive: {
    width: "300px",
    margin: "0 auto",
    display: "block"
  }
}));

const SetDose = ({
  thing,
  open,
  closeModal,
  lastConfigMessageAt,
}) => {
  const classes = useStyles();
  const [buttonState, setButtonState] = useState({ text: messages.setDoseButton, timestamp: null })
  const form = useRef(null);
  const [setDose] = useMutation(SetDoseGQL, {
    onCompleted: closeModal
  });

  if (!open) return '';

  const { id, type } = thing;

  if (buttonState.timestamp && lastConfigMessageAt
    && buttonState.timestamp < new Date(lastConfigMessageAt)) {
    setButtonState({ text: messages.setDoseButton, timestamp: null });
    closeModal();
    return '';
  }

  const handleSetDose = () => {
    form.current.submitForm();
    setButtonState({
      text: messages.setDoseButtonLoading,
      timestamp: new Date(),
    })
  };

  const handleSubmit = (items) => {
    const doses = Object.keys(items).reduce((doses, item) => {
      const [, index, type] = item.match(/([1-4])([AB])/);
      if (!doses[`delivery${index}`]) doses[`delivery${index}`] = {};
      doses[`delivery${index}`][`dosage${type}`] = items[item];
      return doses;
    }, {});
    return setDose({ variables: { id, doses } });
  };

  const getName = (item) => {
    const [, index, type] = item.match(/([1-4])([AB])/);
    return `${type === 'A' ? 'Bottle' : 'Glass'} ${thing[`delivery${index}`].temperature}`;
  };

  const setHide = (item) => {
    const [index] = item.match(/[1-4]/);
    const setting = thing[`delivery${index}`];
    return setting.enabled && setting.canSetDose ? '' : classes.hidden;
  };

  const doses = DELIVERIES.reduce((doses, item) => {
    const [, index, type] = item.match(/([1-4])([AB])/);
    const delivery = thing[`delivery${index}`];
    doses[`delivery${item}`] = ((delivery && delivery[`dosage${type}`]) || 0);
    return doses;
  }, {});

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="alert-dialog-title"><FormattedMessage {...messages.setDoseInvite} /></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage {...messages.setDoseInviteHelp} />
        </DialogContentText>

        <Formik
          initialValues={doses}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          ref={form}
          render={() =>

            <Form className={classes.form}>
              {DELIVERIES.map((item) => <Field
                component={TextField}
                required
                className={setHide(item)}
                id={`idDelivery${item}`}
                label={getName(item)}
                name={`delivery${item}`}
                autoComplete="one-time-code"
                autoFocus={item === '1A'}
                key={`keyField${item}`}
              />)}
            </Form>

          } />

        {type.indexOf("Colonne") !== -1 ?
          <img alt="" className={classes.imgResponsive} src="https://www.castalie.com/wp-content/uploads/2019/11/colonne.jpg" />
          : <img alt="" className={classes.imgResponsive} src="https://www.castalie.com/wp-content/uploads/2019/11/pure-cas.jpg" />}

      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          <FormattedMessage {...messages.close} />
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSetDose}
        >
          <FormattedMessage {...buttonState.text} />
        </Button>


      </DialogActions>
    </Dialog>
  );
};

export default SetDose;
