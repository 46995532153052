/*
 * Thing Messages
 *
 * This contains all the text for the utils / error messages.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.thing';

export default defineMessages({
  thingTitle: {
    id: `${scope}.Thing.title`,
    defaultMessage: 'Fontaine',
  },
  serial: {
    id: `${scope}.Thing.serial`,
    defaultMessage: 'Numéro de série :',
  },
  setDoseButton: {
    id: `${scope}.Thing.setDose.button`,
    defaultMessage: 'Modifer dosage',
  },
  setDoseButtonLoading: {
    id: `${scope}.Thing.setDose.Loadingbutton`,
    defaultMessage: 'Commande en cours ...',
  },
  setDoseInvite: {
    id: `${scope}.Thing.setDose.invite`,
    defaultMessage: 'Sélectionner un volume prédéfinis pour mettre à jour distance le prédosage.',
  },
  setDoseInviteHelp: {
    id: `${scope}.Thing.setDose.invitehelp`,
    defaultMessage: '*La valeur renseignée est en centilitre et doit-être comprise entre 10 cl et 300 cl.',
  },
  rebootInvite: {
    id: `${scope}.Thing.reboot.invite`,
    defaultMessage: 'Souhaitez vous vraiment redémarrer la fontaine?',
  },
  water: {
    id: `${scope}.Thing.water`,
    defaultMessage: 'Eau',
  },
  tankTemperature: {
    id: `${scope}.Thing.temperatureTank`,
    defaultMessage: 'Groupe froid :',
  },
  upTime: {
    id: `${scope}.Thing.upTime`,
    defaultMessage: 'Allumée depuis :',
  },
  lastMessage: {
    id: `${scope}.Thing.lastMessage`,
    defaultMessage: 'Dernier message :',
  },
  reboot: {
    id: `${scope}.Thing.reboot`,
    defaultMessage: 'Redémarrer',
  },
  close: {
    id: `${scope}.Thing.closeButton`,
    defaultMessage: 'Fermer',
  },
  support: {
    id: `${scope}.Thing.support`,
    defaultMessage: 'Support',
  },
  generalFault: {
    id: `${scope}.Thing.generalFault`,
    defaultMessage: 'Défaut général',
  },
  debacterizationFault: {
    id: `${scope}.Thing.debacterizationFault`,
    defaultMessage: 'Défaut fonction Débactérisation',
  },
  deliveryFault: {
    id: `${scope}.Thing.deliveryFault`,
    defaultMessage: 'Défaut fonction Distribution',
  },
  compressorFault: {
    id: `${scope}.Thing.compressorFault`,
    defaultMessage: 'Défaut fonction Compresseur',
  },
  thermoblockFault: {
    id: `${scope}.Thing.thermoblockFault`,
    defaultMessage: 'Défaut fonction Thermobloc',
  },
  mixerMainFailure: {
    id: `${scope}.Thing.mixerMainFailure`,
    defaultMessage: 'Défault alimentation électrique agitateur',
  },
  waterLeak: {
    id: `${scope}.Thing.waterLeak`,
    defaultMessage: 'Fuite eau',
  },
  drainerLevel: {
    id: `${scope}.Thing.drainerLevel`,
    defaultMessage: 'Egoutoir plein',
  },
  lidOpen: {
    id: `${scope}.Thing.lidOpen`,
    defaultMessage: 'Capot ouvert',
  },
  tiltAlert: {
    id: `${scope}.Thing.tiltAlert`,
    defaultMessage: 'Machine instable (vibrations > niveau)',
  },
  configurationFault: {
    id: `${scope}.Thing.configurationFault`,
    defaultMessage: 'Erreur configuration système',
  },
  microControllerFault: {
    id: `${scope}.Thing.microControllerFault`,
    defaultMessage: 'Défaut bus interne',
  },
  communicationFault: {
    id: `${scope}.Thing.communicationFault`,
    defaultMessage: 'Défaut communication modem',
  },
  tankTemperatureAlert:  {
    id: `${scope}.Thing.tankTemperatureAlert`,
    defaultMessage: 'Températures proches de zéro',
  },
  connexion:  {
    id: `${scope}.Thing.connexion`,
    defaultMessage: 'Connexion',
  },

  

});
