import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Things from '../components/things/Things';
import ForgotPasswordContainer from '../components/users/ForgotPasswordContainer';
import SignInContainer from '../components/session/SignInContainer';
import SignOutContainer from '../components/session/SignOutContainer';
import SignUpContainer from '../components/users/SignUpContainer';
import UpdatePasswordContainer from '../components/users/UpdatePasswordContainer';
import VerifyUser from '../components/users/VerifyUser';
import Thing from '../components/thing/Thing';
import Account from '../components/account/Account';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import Contact from '../components/pages/Contact';

const DASHBOARD_PATH = '/';
const THINGS_PATH = '/things';
const THING_PATH = '/thing/:id';
const ACCOUNT_PATH = '/account/:id';

const Routes = () => (
  <Router>
    <PublicRoute path="/sign-in" exact component={SignInContainer} />
    <PublicRoute path="/sign-out" exact component={SignOutContainer} />
    <PublicRoute path="/sign-up/:accountId" component={SignUpContainer} />
    <PublicRoute path="/forgot-password" exact component={ForgotPasswordContainer} />
    <PublicRoute path="/password/update/:token?" exact component={UpdatePasswordContainer} />
    <PublicRoute path="/verify/:token" exact component={VerifyUser} />
    <PrivateRoute path={DASHBOARD_PATH} exact component={Things} />
    <PrivateRoute path={THINGS_PATH} exact component={Things} />
    <PrivateRoute path={THING_PATH} exact component={Thing} />
    <PrivateRoute path={ACCOUNT_PATH} component={Account} />
    <PrivateRoute path="/care" exact component={Contact} />
  </Router>
);

export default Routes;
