import * as yup from 'yup';
import { useSession } from '../../contexts/session';

import Schema from '../../config/Schema';

const validationSchema = yup.object().shape({
  firstname: Schema.name,
  lastname: Schema.name,
  email: Schema.email,
  password: Schema.password,
});

const useSignUp = ({ accountId }) => {
  const { session, signUp } = useSession();

  const handleSubmit = ({ firstname, lastname, email, password }) => {
    signUp({ variables: { firstname, lastname, email, password, accountId } });
  };

  return {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    session,
    validationSchema,
    handleSubmit,
  }
};

export default useSignUp;