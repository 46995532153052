import React from 'react';
import * as yup from 'yup';
import { Mutation } from '@apollo/react-components';
import { Formik } from 'formik';

import UpdatePassword from './UpdatePassword';
import updatePasswordGQL from './updatePassword.gql';

import Schema from '../../config/Schema';

const UpdatePasswordContainer = ({ match: { params } }) => {

  const { token } = params;

  const schema = { newPassword: Schema.password };
  if (!token) {
    schema.oldPassword = Schema.password;
  }
  const validationSchema = yup.object().shape(schema);

  return (
    <Mutation mutation={updatePasswordGQL}>
      {updatePassword => {
        const handleSubmitPassword = ({ newPassword, oldPassword }) => updatePassword(
          { variables: { newPassword, oldPassword, token } }
        );      

        return (
          <Formik
            initialValues={{ newPassword: '', oldPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmitPassword}
            render={() => <UpdatePassword oldPasswordRequired={!token} />} />
        );
      }
      }
    </Mutation>
  );
};

export default UpdatePasswordContainer;
