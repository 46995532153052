import * as yup from 'yup';

// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=?!*()@%&]).{8,50}$/;

const Schema = {
  name: yup
    .string()
    .required(),
  email: yup
    .string()
    .required()
    .email(),
  password: yup
    .string()
    .test(
      'password',
      'Password should be at least 8 characters long, should contain at least one capital letter, one downcase letter and one special character',
      value => (value && value.match(passwordRegex)),
    )
    .required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  token: yup
    .string()
    .min(60)
};

export default Schema;
