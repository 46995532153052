import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Grid } from '@material-ui/core';

import StatutBadge from '../interface/StatutBadge';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    cursor: 'pointer',
  },  
}));

const options = {
  filter: true,
  sort: true,
  print: false,
  download: false,
  disableToolbarSelect: true,
  selectableRows: 'none',
  onRowClick: (dataIndex, rowIndex) => {
    window.location.href = `/thing/${dataIndex[1]}`
  }
};

const columns = [
  {
    name: "alert",
    label: "Etat",
    options: {
      customBodyRender: alert => <StatutBadge color={alert ? 'danger' : 'success'} />
    }
  },
  {
    name: "id",
    label: "ID",
    options: {
      display: "false"
    }
  },
  {
    name: "type",
    label: "Equipement"
  },
  {
    name: "account.name",
    label: "Entreprise"
  },
  {
    name: "lastLocation.zipCode",
    label: "Code postal Equipement",
  },
  {
    name: "lastLocation.city",
    label: "Ville Equipement",
  },
  {
    name: "serial",
    label: "N° de série",
  }
  
];

const ThingsTable = ({ things }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <MUIDataTable
          className={classes.table}
          title="Fontaines"
          data={things}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}

export default ThingsTable;
