import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useSession } from '../../contexts/session';
import sessionGQL from './session.gql';

const SignOutContainer = () => {
  const [redirection, setRedirection] = useState(null);
  const { signOut } = useSession();

  useEffect(() => {
    signOut({
      update: (client) => {
        // Read the session query cache
        const data = client.readQuery({ query: sessionGQL });
        if (data && data.session) {
          data.session = { ...data.session, user: null, token: null};
          // reset the session query cache
          client.writeQuery({ query: sessionGQL, data });
        }
      }
    });
    setRedirection(true);
  }, [redirection, signOut]);

  if (redirection) return (<Redirect to="/sign-in" />);
  return (<CircularProgress />);
};

export default SignOutContainer;
