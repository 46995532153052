import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CircularProgress } from '@material-ui/core';
import { useSession } from '../../contexts/session';

import ThingsTable from './ThingsTable';

import ThingsGQL from './things.gql';


const Things = () => {
  const { loading, error, data } = useQuery(ThingsGQL);
  const { session } = useSession();

  if (loading) return (<CircularProgress />);
  if (error) { console.error(error); return ''; }
  let {things} = data
  // TO CHANGE AFTER ALEX ANSWER
  if ( session.user.email.indexOf('castalie.com') === -1 && session.user.email !== "alexandre.leclercq@gmail.com" ){
    things = things.filter(thing => thing.account.technicalContact.id === session.user.id);
  }

  if (!things) return <div>No machines</div>;

  return (<ThingsTable things={things} />)
};


export default Things;
